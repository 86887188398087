import { render, staticRenderFns } from "./addPeople.vue?vue&type=template&id=e086af18&scoped=true"
import script from "./addPeople.vue?vue&type=script&lang=js"
export * from "./addPeople.vue?vue&type=script&lang=js"
import style0 from "./addPeople.vue?vue&type=style&index=0&id=e086af18&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e086af18",
  null
  
)

export default component.exports
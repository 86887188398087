<!-- 精神病患者新增/编辑弹窗-->
<template>
  <div>
    <el-dialog
      :z-index="10"
      :title="title"
      :visible.sync="visible"
      width="60%"
      @close="closeModal"
      :close-on-click-modal="false"
    >
      <!-- 左边竖线样式写法 -->
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
        "
      >
        <div style="display: flex; align-items: center; margin-bottom: 2%">
          <div style="border-left: 4px solid #556bff">&nbsp;</div>
          <div class="search-title">基本信息</div>
        </div>
        <a-button v-if="isEdit" type="primary" @click="choosePeople"
          >选择居民</a-button
        >
      </div>

      <!-- 基本信息 -->
      <div
        v-if="noChoice"
        style="
          background-color: #fff8de;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 30px;
        "
      >
        <div style="margin-top: 6px; margin-bottom: 6px">
          &nbsp;
          <a-icon type="info-circle" style="color: #e6a23c" />请选择人员
        </div>
      </div>
      <ax-form v-else ref="form" :formBuilder="formBuilder1">
        <div slot="file">
          <imagePreview ref="img1" :businessId="businessId"></imagePreview>
        </div>
      </ax-form>
      <div style="display: flex; align-items: center; margin-bottom: 2%">
        <div style="border-left: 4px solid #556bff">&nbsp;</div>
        <div class="search-title">患者监护人信息</div>
      </div>
      <!-- 患者监护人信息 -->
      <ax-form ref="form1" :formBuilder="formBuilder2"> </ax-form>
      <div style="display: flex; align-items: center; margin-bottom: 2%">
        <div style="border-left: 4px solid #556bff">&nbsp;</div>
        <div class="search-title">患者患病情况</div>
      </div>
      <!-- 患者患病情况 -->
      <ax-form ref="form2" :formBuilder="formBuilder3">
        <!-- 危险等级 -->
        <div slot="dangerLevel" style="display: flex; align-items: center">
          <a-select
            placeholder="请选择"
            v-model="dangerLevel"
            allowClear
            v-decorator="[
              'dangerLevel',
              { rules: [{ required: true, message: '请选择危险等级' }] },
            ]"
          >
            <a-select-option
              v-for="(item, index) in dangerLevelList"
              :key="index"
              :value="item.value"
              >{{ item.label }}</a-select-option
            >
          </a-select>
          <a-popconfirm placement="topLeft">
            <a-icon
              slot="icon"
              type="question-circle-o"
              style="color: #e6a23c"
            />
            <template slot="title">
              <div class="toolTipTitle">
                <a-button
                  style="
                    color: #2d3138;
                    background-color: #e6e9ee;
                    border: #e6e9ee;
                  "
                  >0级</a-button
                >
                <div>无符合以下1-5级中的任何行为</div>
              </div>
              <div class="toolTipTitle">
                <a-button
                  style="
                    color: #d48806;
                    background-color: #fffbe6;
                    border: #fffbe6;
                  "
                  >1级</a-button
                >
                <div>口头威胁、喊叫、但没有打砸行为</div>
              </div>
              <div class="toolTipTitle">
                <a-button
                  style="
                    color: #d48806;
                    background-color: #fffbe6;
                    border: #fffbe6;
                  "
                  >2级</a-button
                >
                <div>打砸行为，局限在家里，针对财务，能被劝说制止</div>
              </div>
              <div class="toolTipTitle">
                <a-button
                  style="
                    color: #d48806;
                    background-color: #fffbe6;
                    border: #fffbe6;
                  "
                  >3级</a-button
                >
                <div>明显打砸行为，不分场合，针对财务，不能被劝说制止</div>
              </div>
              <div class="toolTipTitle">
                <a-button
                  style="
                    color: #cf1322;
                    background-color: #fff1f0;
                    border: #fff1f0;
                  "
                  >4级</a-button
                >
                <div>
                  持续的打砸行为，不分场合，针对财务或人，不能接受劝说制止。包括自伤、自杀
                </div>
              </div>
              <div class="toolTipTitle">
                <a-button
                  style="
                    color: #cf1322;
                    background-color: #fff1f0;
                    border: #fff1f0;
                  "
                  >5级</a-button
                >
                <div>
                  持械针对人的任何暴力行为，或者纵火、爆炸等行为。无论在家里还是公共场合
                </div>
              </div>
            </template>
            <a-icon
              type="question-circle"
              style="
                color: #e6a23c;
                margin-left: 2%;
                font-size: 0.08rem;
                cursor: pointer;
              "
            />
          </a-popconfirm>
          <!-- <a-tooltip placement="topLeft" style="background-color: #fff;">
            
            <div slot="title" style="color: #556bff;background-color: #fff;">title</div>
          </a-tooltip> -->
        </div>
      </ax-form>
      <div style="display: flex; align-items: center; margin-bottom: 2%">
        <div style="border-left: 4px solid #556bff">&nbsp;</div>
        <div class="search-title">患者医疗保障情况</div>
      </div>
      <!-- 患者医疗保障情况 -->
      <ax-form ref="form3" :formBuilder="formBuilder4"> </ax-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="onSubmit"
          :loading="saveType"
          style="background-color: #556bff"
          >保 存</el-button
        >
        <el-button @click="visible = false">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 选择居民弹窗 -->
    <choose-people
      ref="choosePeople"
      @receive="receivePeopleId"
      :selectedPeopleList="selectedPeopleList"
    ></choose-people>
  </div>
</template>
<script>
const formList1 = [
  {
    label: "姓名",
    type: "",
    model: "residentName",
    options: { allowClear: true, placeholder: "请输入" },
    col: { span: 12 },
    rules: [{ required: false, message: "请输入姓名" }],
  },
  {
    label: "个人照片",
    type: "",
    model: "file",
    options: {},
    col: { span: 12 },
    rules: [{ required: false, message: "" }],
  },
  {
    label: "身份证号",
    type: "",
    model: "identityCard",
    options: { allowClear: true, maxLength: 18, placeholder: "请输入" },
    col: { span: 12 },
    rules: [
      {
        required: false,
        message: "请输入",
        pattern:
          /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
        message: "请输入正确格式的身份证号",
      },
    ],
  },
  {
    label: "性别",
    type: "select",
    model: "gender",
    options: { disabled: true, placeholder: "请先填写身份证号" },
    col: { span: 12 },
    rules: [{ required: false, message: "请先填写身份证号" }],
  },
  {
    label: "年龄",
    type: "",
    model: "age",
    options: {
      disabled: true,
      placeholder: "请先填写身份证号",
    },
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "出生日期",
    type: "datePicker",
    model: "birthDate",
    options: {
      disabled: true,
      placeholder: "请先填写身份证号",
    },
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "联系电话",
    type: "",
    model: "phone",
    options: { placeholder: "请输入" },
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "现住区域",
    type: "",
    model: "currentArea",
    options: {},
    col: { span: 24 },
    rules: [{ required: false }],
  },
  {
    label: "详细地址",
    type: "",
    model: "detailedAddress",
    options: { placeholder: "请选择详细地址" },
    col: { span: 12 },
    rules: [{ required: false }],
  },
];
const formList2 = [
  {
    label: "监护人姓名",
    type: "",
    model: "guardianName",
    options: { maxLength: 20, placeholder: "请输入" },
    col: { span: 12 },
    rules: [{ required: true, message: "请输入监护人姓名" }],
  },
  {
    label: "身份证号",
    type: "",
    model: "guardianCardId",
    options: { placeholder: "请输入", maxLength: 18 },
    col: { span: 12 },
    rules: [
      {
        required: true,
        pattern:
          /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
        message: "请输入正确格式的身份证号",
      },
    ],
  },
  {
    label: "联系方式",
    type: "",
    model: "guardianPhone",
    options: { placeholder: "请输入", maxLength: 11 },
    col: { span: 12 },
    rules: [
      {
        required: true,
        message: "请输入联系方式",
        pattern: /^1[3-9]\d{9}$/,
        message: "请输入正确格式的联系电话",
      },
    ],
  },
  {
    label: "与患者关系",
    type: "select",
    model: "relation",
    options: {},
    col: { span: 12 },
    rules: [{ required: true, message: "请选择与患者关系" }],
  },
  {
    label: "服务处所",
    type: "",
    model: "serviceSpace",
    options: {
      maxLength: 50,
      placeholder: "请输入",
    },
    col: { span: 12 },
    rules: [{ required: false, message: "请输入服务处所" }],
  },
  {
    label: "住址",
    type: "",
    model: "guardianAddress",
    options: {
      placeholder: "请输入",
      maxLength: 50,
    },
    col: { span: 12 },
    rules: [{ required: false, message: "请输入住址" }],
  },
];
const formList3 = [
  {
    label: "患者诊断名称",
    type: "select",
    model: "diseaseName",
    options: {},
    col: { span: 12 },
    rules: [{ required: true }],
  },
  {
    label: "危险性等级",
    type: "",
    model: "dangerLevel",
    options: {},
    // help提示
    // formItem: {labelHelp:'0级 无符合以下1-5级中的任何行为 \r\n 1级 口头威胁、喊叫、但没有打砸行为\r\n2\r\n3\r\n4\r\n'},
    col: { span: 12 },
    rules: [{ required: true }],
  },
  {
    label: "诊断单位",
    type: "",
    model: "diagnosticUnit",
    options: {
      maxLength: 20,
      placeholder: "请输入",
    },
    col: { span: 12 },
    rules: [{ required: false }],
  },

  {
    label: "诊断医生",
    type: "",
    model: "diagnostician",
    options: {
      maxLength: 20,
      placeholder: "请输入",
    },
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "首次发病日期",
    type: "datePicker",
    model: "onsetDate",
    options: {
      placeholder: "请选择",
    },
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "关锁情况",
    type: "select",
    model: "lockCondition",
    options: {},
    col: { span: 12 },
    rules: [{ required: false }],
  },
];
const formList4 = [
  {
    label: "患者类别",
    type: "select",
    model: "patientType",
    options: {},
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "低保情况",
    type: "radio",
    model: "subsidyStatus",
    options: {
      options: [
        { label: "有", value: "0" },
        { label: "无", value: "1" },
      ],
    },
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "经济状态",
    type: "select",
    model: "economicStatus",
    options: {},
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "有无社会组织参与康复服务",
    type: "radio",
    model: "serviceFlag",
    options: {
      options: [
        { label: "有", value: "0" },
        { label: "无", value: "1" },
      ],
    },
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "医保情况",
    type: "select",
    model: "medicalInsuranceType",
    options: {},
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "其他情况",
    type: "",
    model: "otherConditions",
    options: {
      placeholder: "请输入",
      maxLength: 200,
    },
    col: { span: 24 },
    rules: [{ required: false }],
  },
];
import api from "./api";
import imagePreview from "./image-preview/imagePreview";
import ChoosePeople from "./choosePeople.vue";
export default {
  components: {
    imagePreview,
    ChoosePeople,
    choosePeopleId: "",
  },
  data() {
    return {
      options: [],
      dangerLevelList: [],
      visible: false,
      noChoice: true,
      isEdit: true,
      businessId: "",
      id: "",
      title: "",
      disabled: false,
      fileIds: [], //图片数组
      saveType: false,
      formBuilder1: this.$common.initGridFormData(
        formList1,
        { layout: "horizontal", labelWidth: 180, disabled: true },
        { col: { span: 8 } }
      ),
      formBuilder2: this.$common.initGridFormData(
        formList2,
        { layout: "horizontal", labelWidth: 180 },
        { col: { span: 8 } }
      ),
      formBuilder3: this.$common.initGridFormData(
        formList3,
        { layout: "horizontal", labelWidth: 180 },
        { col: { span: 12 } }
      ),
      formBuilder4: this.$common.initGridFormData(
        formList4,
        { layout: "horizontal", labelWidth: 180 },
        { col: { span: 12 } }
      ),
      baseInfoValue: {}, //基本信息
      guardianValue: {}, //监护人信息
      diseaseValue: {}, //患病情况
      medicalValue: {}, //医疗保障情况
      dangerLevel: "",
      dangerLevel_dictText: "",
      selectedPeopleList: [],
    };
  },
  watch: {
    businessId(newval) {
      console.log(newval, "412");
      //   setTimeout(()=>{
      //     this.$refs.img1.getFileList(newval)
      //   },3000)
      //   this.businessId = newval;
    },
  },
  computed: {},
  mounted() {},
  methods: {
    // 打开选择居民弹窗
    choosePeople() {
      this.$refs.choosePeople.openModal();
    },
    // 接收子组件传过来的peopleId
    receivePeopleId(item) {
      console.log(item);
      if (item[0]) {
        this.noChoice = false;
        this.getPersonInfo(item[0]);
        this.businessId = item[0];
        this.$nextTick(() => {
          this.$refs.img1.getPicList(item[0]);
        });
      }
    },
    // 级联选择器改变的回调
    onChange(value) {
      console.log(value);
    },
    // 打开弹窗
    openModal(record = {}) {
      this.visible = true;
      this.noChoice = true;
      this.isEdit = true;
      this.saveType = false
      this.title = record.title;
      this.selectedPeopleList = record.selectedPeopleList;
      this.$nextTick(() => {
        this.getDictionsary();
        this.$refs.form1.resetFields();
        this.$refs.form2.resetFields();
        this.$refs.form3.resetFields();
      });
      if (record.title === "编辑精神病患者信息") {
        this.noChoice = false;
        this.isEdit = false;
        this.id = record.id;
        // 赋值businessId回显查询已上传图片
        this.businessId = record.residentId;
        this.getPsychiatricPatientsInfo(record.id);
        this.getBaseInfo(record.residentId);
        this.$nextTick(() => {
          this.$refs.img1.getPicList(record.residentId);
        });
      } else {
        this.id = "";
      }
    },
    // 关闭弹窗
    closeModal() {
      this.businessId = "";
      this.visible = false;
      this.$refs.form1.resetFields();
      this.$refs.form2.resetFields();
      this.$refs.form3.resetFields();
      this.$emit("refsh");
    },
    // 获取字典
    async getDictionsary() {
      // 与患者关系//人员关系
      await api.dictData({ dicKind: "personnel_relation" }).then((res) => {
        const options = res.data.map((res) => {
          return { label: res.dicDesc, value: res.dicCode };
        });
        this.$refs.form1.setFormItemProp("relation", {
          options: { options, allowClear: true, placeholder: "请选择" },
        });
      });
      // 精神病病情诊断名称
      await api.dictData({ dicKind: "psychopath_disease_name" }).then((res) => {
        const options = res.data.map((res) => {
          return { label: res.dicDesc, value: res.dicCode };
        });
        this.$refs.form2.setFormItemProp("diseaseName", {
          options: { options, allowClear: true, placeholder: "请选择" },
        });
      });
      // 精神病危险等级
      await api.dictData({ dicKind: "psychopath_danger_level" }).then((res) => {
        const options = res.data.map((res) => {
          return { label: res.dicDesc, value: res.dicCode };
        });
        this.dangerLevelList = options;
      });
      // 精神病关锁情况
      await api
        .dictData({ dicKind: "psychopath_lock_condition" })
        .then((res) => {
          const options = res.data.map((res) => {
            return { label: res.dicDesc, value: res.dicCode };
          });
          this.$refs.form2.setFormItemProp("lockCondition", {
            options: { options, allowClear: true, placeholder: "请选择" },
          });
        });
      // 精神病患者类别
      await api.dictData({ dicKind: "psychopath_patient_type" }).then((res) => {
        const options = res.data.map((res) => {
          return { label: res.dicDesc, value: res.dicCode };
        });
        this.$refs.form3.setFormItemProp("patientType", {
          options: { options, allowClear: true, placeholder: "请选择" },
        });
      });
      // 精神病经济状态
      await api
        .dictData({ dicKind: "psychopath_economic_status" })
        .then((res) => {
          const options = res.data.map((res) => {
            return { label: res.dicDesc, value: res.dicCode };
          });
          this.$refs.form3.setFormItemProp("economicStatus", {
            options: { options, allowClear: true, placeholder: "请选择" },
          });
        });
      // 精神病医保情况
      await api
        .dictData({ dicKind: "psychopath_medical_insurance_type" })
        .then((res) => {
          const options = res.data.map((res) => {
            return { label: res.dicDesc, value: res.dicCode };
          });
          this.$refs.form3.setFormItemProp("medicalInsuranceType", {
            options: { options, allowClear: true, placeholder: "请选择" },
          });
        });
      // 精神病医保情况
      await api
        .dictData({ dicKind: "psychopath_medical_insurance_type" })
        .then((res) => {
          const options = res.data.map((res) => {
            return { label: res.dicDesc, value: res.dicCode };
          });
          this.$refs.form3.setFormItemProp("medicalInsuranceType", {
            options: { options, allowClear: true, placeholder: "请选择" },
          });
        });
    },
    // 身份证号计算性别/年龄/出生日期
    calculateIDCardInfo(idCard) {
      let info = {
        gender: "",
        age: "",
        birthDate: "",
      };

      if (!idCard || (idCard.length !== 15 && idCard.length !== 18)) {
        return info;
      }

      let year =
        idCard.length === 15 ? "19" + idCard.substr(6, 2) : idCard.substr(6, 4);
      let month = idCard.substr(idCard.length === 15 ? 8 : 10, 2);
      let day = Number(idCard.substr(idCard.length === 15 ? 10 : 12, 2)) + 1;

      let genderCode =
        idCard.substr(idCard.length - 2, 1) % 2 === 0 ? "女" : "男";
      let birthday = new Date(year, parseInt(month) - 1, day); // 将月份减一
      let age = new Date().getFullYear() - birthday.getFullYear();
      info.gender = genderCode;
      info.age = age;
      info.birthDate = birthday.toISOString().slice(0, 10);
      this.$refs.form.setFieldsValue({ birthDate: info.birthDate });
      this.$refs.form.setFieldsValue({ gender: info.gender });
      this.$refs.form.setFieldsValue({ age: info.age });
      return info;
    },
    // 提交
    async onSubmit() {
      if (this.noChoice) {
        this.$message.warning("请先选择居民");
        return;
      }
      this.$refs.form.form.validateFields(async (err, value) => {
        if (err) return;
        this.baseInfoValue.residentId = this.businessId;
        console.log(this.baseInfoValue);
        if (this.$refs.form1) {
          this.$refs.form1.form.validateFields(async (err, value1) => {
            if (err) return;
            console.log(value1);
            this.guardianValue = value1;
            if (this.$refs.form2) {
              this.$refs.form2.form.validateFields(async (err, value2) => {
                if (err) return;
                console.log(value2);
                this.diseaseValue = value2;
                this.diseaseValue.dangerLevel = this.dangerLevel || "";
                this.diseaseValue.lockCondition = value2.lockCondition || "";
                if (this.$refs.form3) {
                  this.$refs.form3.form.validateFields(async (err, value3) => {
                    if (err) return;
                    console.log(value3);
                    this.medicalValue = value3;
                    this.medicalValue.patientType = value3.patientType || "";
                    this.medicalValue.economicStatus =
                      value3.economicStatus || "";
                    this.medicalValue.medicalInsuranceType =
                      value3.medicalInsuranceType || "";
                    const totalValue = {
                      ...this.baseInfoValue,
                      ...this.guardianValue,
                      ...this.diseaseValue,
                      ...this.medicalValue,
                    };
                    console.log(totalValue, "375新增数据");
                    this.saveType = true;
                    if (this.id) {
                      totalValue.id = this.id;
                      const res = await api.updateKeyPopulation(totalValue);
                      console.log(res);
                      if (res.status === 200) {
                        this.$message.success("修改成功");
                        this.saveType = false;
                        this.closeModal();
                        this.$refs.form.resetFields();
                        this.$refs.form1.resetFields();
                        this.$refs.form2.resetFields();
                        this.$refs.form3.resetFields();
                      }
                    } else {
                      const res = await api.addKeyPopulation(totalValue);
                      console.log(res);
                      if (res.status === 200) {
                        this.$message.success("新增成功");
                        this.saveType = false;
                        this.closeModal();
                        this.$refs.form.resetFields();
                        this.$refs.form1.resetFields();
                        this.$refs.form2.resetFields();
                        this.$refs.form3.resetFields();
                      }
                    }
                  });
                }
              });
            }
          });
        }
      });
    },
    // 根据id获取居民信息详情
    async getPersonInfo(id) {
      const res = await api.getResidentArchivesById(id);
      console.log(res, "回显详情");
      this.calculateIDCardInfo(res.data.identityCard);
      this.$refs.form.setFieldsValue(res.data);
      this.$refs.form.setFieldsValue({
        detailedAddress: res.data.cellName
          ? `${res.data.cellName}/${res.data.buildingNum}/${res.data.unitNum}/${res.data.floorNum}/${res.data.accountNum}`
          : "",
      });
    },
    // 根据id获取精神病人信息
    async getPsychiatricPatientsInfo(id) {
      const res = await api.getKeyPopulationById(id);
      console.log(res, "回显精神病人详情");
      this.dangerLevel_dictText = res.data.dangerLevel_dictText;
      this.dangerLevel = res.data.dangerLevel;
      this.$refs.form1.setFieldsValue(res.data);
      this.$refs.form2.setFieldsValue(res.data);
      this.$refs.form3.setFieldsValue(res.data);
    },
    async getBaseInfo(id) {
      const res = await api.getResidentArchivesById(id);
      console.log(res, "回显详情");
      this.calculateIDCardInfo(res.data.identityCard);
      this.$refs.form.setFieldsValue(res.data);
      this.$refs.form.setFieldsValue({
        detailedAddress: res.data.cellName
          ? `${res.data.cellName}/${res.data.buildingNum}/${res.data.unitNum}/${res.data.floorNum}/${res.data.accountNum}`
          : "",
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-dialog {
  border-radius: 20px !important;
}

.dialog-footer {
  display: flex;
  justify-content: end;
}

.search-title {
  // font-size: 0.072rem;
  font-weight: 800;
}
.toolTipTitle {
  display: flex;
  align-items: center;
  margin-top: 1%;
  div {
    margin-left: 2%;
  }
}
</style>